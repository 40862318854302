import { DeepPartial } from '@fluentui/merge-styles';
import {
  IButtonStyles,
  IDatePickerStyleProps,
  IDatePickerStyles,
  IDropdownStyleProps,
  IDropdownStyles,
  INavStyles,
  ITextFieldStyleProps,
  ITextFieldStyles,
} from '@fluentui/react';

export const STextFieldStyle = (
  props: ITextFieldStyleProps,
): DeepPartial<ITextFieldStyles> => ({
  fieldGroup: {
    backgroundColor: props.theme.palette.whiteTranslucent40,
  },
});

export const STextFormFieldStyle = (
  props: ITextFieldStyleProps,
): DeepPartial<ITextFieldStyles> => ({
  fieldGroup: {
    backgroundColor: 'white',
    height: '52px',
    width: '322px',
    borderRadius: '8px',
  },
});

export const STextFormFieldStyleMobileView = (
  props: ITextFieldStyleProps,
): DeepPartial<ITextFieldStyles> => ({
  fieldGroup: {
    backgroundColor: 'white',
    height: '52px',
  },
});

export const STextAreaFieldStyle = (
  props: ITextFieldStyleProps,
): DeepPartial<ITextFieldStyles> => ({
  fieldGroup: {
    backgroundColor: props.theme.palette.whiteTranslucent40,
    minHeight: 100,
  },
});

export const SUploadFieldStyle = (
  props: ITextFieldStyleProps,
): DeepPartial<ITextFieldStyles> => ({
  fieldGroup: {
    backgroundColor: props.theme.palette.whiteTranslucent40,
  },
  suffix: {
    cursor: 'pointer',
    color: props.theme.palette.themePrimary,
    backgroundColor: props.theme.palette.white,
    width: 100,
    fontSize: 14,
    justifyContent: 'center',
    fontWeight: 600,
    borderLeft: 'solid 1px rgba(0,0,0,0.5)',
  },
});

export const SDropdownStyle = (
  props: IDropdownStyleProps,
): DeepPartial<IDropdownStyles> => ({
  title: {
    backgroundColor: props.theme?.palette.whiteTranslucent40,
  },
});

export const SDatePickerStyle = (
  props: IDatePickerStyleProps,
): DeepPartial<IDatePickerStyles> => ({});
export const SDatePickerTextFieldStyle = (
  props: ITextFieldStyleProps,
): DeepPartial<ITextFieldStyles> => ({
  fieldGroup: {
    backgroundColor: props.theme.palette.whiteTranslucent40,
  },
});

export const SIconButton: Partial<IButtonStyles> = {
  rootHovered: {
    backgroundColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: 'transparent',
  },
  rootExpanded: {
    backgroundColor: 'transparent',
  },
  icon: {
    color: '#c1bfbf',
    lineHeight: 'unset',
    fontSize: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export const SCloseButton: Partial<IButtonStyles> = {
  rootHovered: {
    backgroundColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: 'transparent',
  },
  rootExpanded: {
    backgroundColor: 'transparent',
  },
  icon: {
    width: 25,
    height: 25,
  },
};
export const SAudioButton: Partial<IButtonStyles> = {
  rootHovered: {
    backgroundColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: 'transparent',
  },
  rootExpanded: {
    backgroundColor: 'transparent',
  },
  icon: {
    width: 35,
    height: 35,
    fontSize: 35,
    lineHeight: 'unset',
  },
};

export const navStyles: Partial<INavStyles> = {
  chevronButton: {
    right: 12,
    left: 'unset',
  },
};
